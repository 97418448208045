import React from "react"

import arrow from '../../assets/images/arrow-dark.png';

import { cls } from '../../frontend-shared/helpers';

import './ArrowButton.css';

const ArrowButton = ({ size = 'md' }) => (
  <div className={cls('ArrowButton', { size }, 'rounded-full flex flex-row items-center justify-center bg-[#f2f5ff]')}>
    <img className="w-1/2 m-0" src={arrow} />
  </div>
);

export default ArrowButton;
