import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react"

import { components } from "../components/Feature"
import SEO from "../components/seo"
import Layout from "../components/layout"
const Feature = (props) => {
  const { data } = props
  if (!data) return null
  const pageData = data.prismicFeature;
  const page = pageData.data || {}
  return (
    <Layout
      className="background1"
      theme={props.pageContext.header_theme && props.pageContext.header_theme}
    >
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />
    </Layout>
  );
};

export default Feature;

export const query = graphql`
  query featureQuery($uid: String!){
    prismicFeature(uid: {eq: $uid}) {
      uid
      id
      type
      data {
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...FeatureHeroSlice
          ...FeatureContentSlice
          ...FeatureConclusionSlice
        }
      }
    }
  }
`
