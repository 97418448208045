import React from "react"

import { graphql } from 'gatsby';

import LinearContentBlock from '../Elements/LinearContentBlock';

const Content = ({ slice: { items } }) => (
  <div className="relative w-full">
    <div
      className="bg8 hidden lg:block -right-32 top-96 absolute"
      style={{ transform: 'rotate(60deg)' }}
    />
    <div
      className="bg8 hidden lg:block left-1/4 -top-48 absolute"
      style={{ transform: 'translateX(-50%) scale(-0.5, 0.5) rotate(10deg)' }}
    />
    <div className="max-w-7xl mx-auto relative z-10 lg:mt-64">
      <LinearContentBlock
        entries={items.map(({ description: { richText }, ...rest }) => ({
          content: richText, ...rest
        }))}
      />
    </div>
  </div>
);

export default Content;

export const query = graphql`
  fragment FeatureContentSlice on PrismicFeatureDataBodyContent {
    id
    slice_type
    items {
      title
      description {
        richText
      }
      image {
        dimensions {
          width
          height
        }
        fluid {
          srcSetWebp
          srcWebp
        }
      }
      button_text
      button_link
    }
  }
`
