import React from "react"

import { graphql } from 'gatsby';

import { PrismicRichText } from '@prismicio/react';

import ArrowButton from '../Elements/ArrowButton';
import CustomerExperience from '../Elements/CustomerExperience';
import FaqSection from '../Elements/FaqSection';
import Link from '../Elements/Link';
import Testimonials from '../Elements/Testimonials';

const Conclusion = ({
  slice: {
    primary: {
      another_feature_text: text,
      another_feature_link: link,
      another_feature_image: image,
    },
    items,
  },
}) => (
  <>
    <Testimonials />
    <div className="relative w-full flex flex-row flex-wrap items-center justify-between py-[15vw]">
      <img
        className="w-[35vw] m-0"
        src={image?.fluid?.srcWebp}
        srcSet={image?.fluid?.srcSetWebp}
      />
      <Link
        className="no-underline relative z-10 flex-grow flex flex-row items-center justify-center gap-8 mt-16 px-4 md:px-0"
        href={link}
      >
        <PrismicRichText
          field={text.richText}
          components={{
            heading2: ({ children }) => (
              <h2 className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-blue font-medium m-0">
                {children}
              </h2>
            ),
            paragraph: ({ children }) => (
              <p className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-blue font-medium font1 m-0">
                {children}
              </p>
            ),
          }}
        />
        <ArrowButton size="lg" />
      </Link>
    </div>
    <FaqSection slice={{ items }} />
    <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
      <CustomerExperience items="clients" />
    </div>
  </>
);

export default Conclusion;

export const query = graphql`
  fragment FeatureConclusionSlice on PrismicFeatureDataBodyConclusion {
    id
    slice_type
    primary {
      another_feature_text { richText }
      another_feature_link
      another_feature_image {
        fluid {
          srcWebp
          srcSetWebp
        }
      }
    }
    items {
      faq {
        document {
          ... on PrismicFaq {
            id
            data {
              question {
                text
                html
              }
              answer {
                text
                html
                richText
              }
            }
          }
        }
      }
    }
  }
`
