import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText } from '@prismicio/react';

import GetStartedButton from '../Buttons/ButtonGetStarted';
import WatchDemoButton from '../Buttons/ButtonWatchDemo';

import OutOfTheBoxContent from '../Elements/OutOfTheBoxContent';
import TrustedBrands from '../Elements/TrustedBrands';

const HeroSection = ({
  slice: {
    primary: {
      title,
      description: { richText: description },
      image,
    },
  },
}) => (
  <>
    <div className="w-full flex flex-row pt-24 lg:pt-36 relative lg:min-h-[56rem] lg:h-[100vh]">
      <div className="order-first flex flex-col gap-2 h-full py-24 z-10 px-4 sm:pl-6 md:pl-12 lg:pl-28 xl:pl-32 2xl:pl-64">
        <h2 className="text-blue font-medium 2xl:text-7xl xl:text-6xl lg:text-5xl sm:text-4xl text-3xl tracking-tighter">
          {title}
        </h2>
        <div></div>
        <PrismicRichText
          field={description}
          components={{
            paragraph: ({ children }) => (
              <p className="text-lg text-blue pb-3">
                {children}
              </p>
            ),
          }}
        />
        <div className="flex flex-row gap-4 flex-wrap">
          <GetStartedButton className="m-0" />
          <WatchDemoButton className="m-0" />
        </div>
        <div className="flex-grow" />
      </div>
      <div className="flex-grow" />
      <div className="max-w-[60vw] lg:max-w-[70vw] hidden md:flex flex-col items-right justify-end w-full order-last -mb-[20vw] -mr-[12vw]">
        <GatsbyImage
          className="w-full"
          image={getImage(image)}
        />
      </div>
      <GatsbyImage
        className="md:hidden w-[360px] -bottom-[96px] -right-[130px]"
        image={getImage(image)}
        style={{ position: 'absolute' }}
      />
    </div>
    <TrustedBrands className="sm:pb-20 pt-40 md:pt-24 pb-48 md:pb-56 md:-mb-40 z-10" />
    <OutOfTheBoxContent />
  </>
);

export default HeroSection;

export const query = graphql`
  fragment FeatureHeroSlice on PrismicFeatureDataBodyHero {
    id
    slice_type
    primary {
      title
      description { richText }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
